@import url("styles/contact.css");


.App {
  text-align: center;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #dee2e6;
  font-size: 1vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.resume {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  margin: auto;
  margin-top: 20px;
}

/* button {
  margin-top: 10px;
} */

#linkedInLogo {
  filter: brightness(0%);
}

.logo-container img {
  margin: 10px;
}

.game-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5%;
}

.resume-row {
  display: flex;
  justify-content: center;
}

.body-container {
  margin-top: 5%;
}

#solitaire_img{
  padding: 13px;
}

.abstract {
  width: 75%;
}

.bold-underline {
  text-decoration: underline;
  font-weight: bold;
}

.opener {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.opener div {
  margin: 20px;
}

.headshot {
  border-radius: 20%;
  border-style: solid;
  border-color: #ffffff;
  border-width: 3px;
  width: 12vw;
}

textarea {
  resize: none;
  width: 100%;
}

input {
  width: 100%;
}

form > * {
  margin: 1px;
}

nav {
  font-size: 1.2vw;
} 

h1 {
  font-size: 3vw;
}

h4 {
  font-size: 2vw;
}

h5 {
  font-size: 1.5vw;
}

.nav-link {
  margin-right: 0.5vw;
}

.card {
  width: 5vw;
  max-width: 20vw;
}

p {
  font-size: 1.1vw;
  width:100%;
}

.btn {
  font-size: 1.2vw;
}

.card-title {
  font-size: 1.5vw;
}

.academia {
  text-align: left;
}